import { motion } from "framer-motion";
import { PiFileArrowDown } from "react-icons/pi";

export function Career() {
  return (
    <div className="grid grid-cols-12 px-6 md:px-0">
      <div className="flex flex-col-reverse items-center justify-center col-span-12 mt-4 md:items-start md:col-span-11 md:gap-2 md:text-lg">
        <motion.div
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <a
            download="Prajjwal-Resume.pdf"
            href="/Prajjwal-Resume.pdf"
            className="flex items-center justify-center gap-2 mt-4 cursor-pointer dark:text-primaryLight text-primary"
          >
            <h2 className="text-lg md:text-xl">Resume</h2>
            <PiFileArrowDown className="w-5 h-5" />
          </a>
        </motion.div>

        <p className="mt-2">
          Aug 2018 - Completed Bachelors of Technology in Computer Science from
          Institute of Technology Gopeshwar.
        </p>
        <p className="mt-2">
          Jan 2021 - Joined Jio Platforms as Software Developer Intern and
          worked on porting 2 existing SAP applications to ReactJS, Node and
          MSSQL.
        </p>
        <p className="mt-2">
          Aug 2021 - Completed Masters of Technology in Computer Science from
          National Institute of Technology Goa with 9.3 CGPA.
        </p>
        <p className="mt-2">
          Aug 2021 - Joined Vehant Technologies as Full Stack Developer and
          worked on Okean.
        </p>
        <p className="mt-2">
          May 2022 - Joined Argonaut as Full Stack Developer and currently
          working there since.
        </p>
      </div>
    </div>
  );
}
