import { useEffect, useState } from "react";
import PrajSign from "../assets/PrajSign.svg";
import { PiXBold, PiHamburger, PiMoon, PiSun } from "react-icons/pi";
import { useLocalStorage } from "@uidotdev/usehooks";

import { Link } from "react-scroll";
import { AnimatePresence, motion } from "framer-motion";

export function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navLinks = [
    {
      to: "home",
      text: "Home",
    },
    {
      to: "about",
      text: "About",
    },
    {
      to: "work",
      text: "Work",
    },
    {
      to: "blogs",
      text: "Blogs",
    },
  ];

  const preferColorSchemeValue = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches
    ? "dark"
    : "light";

  const [theme, setTheme] = useLocalStorage("theme", preferColorSchemeValue);

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  function toggleTheme() {
    setTheme(theme === "dark" ? "light" : "dark");
  }

  return (
    <>
      <AnimatePresence>
        {isNavOpen && (
          <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-30 grid w-screen h-screen grid-cols-12 gap-4 grid-rows-8 backdrop-blur bg-white/70 dark:bg-black/80"
          >
            <div className="flex items-center justify-end col-span-12 px-10 cursor-pointer">
              <PiXBold
                className="w-5 h-5 text-neutralTextLight dark:text-neutralTextDark opacity-60"
                onClick={() => setIsNavOpen(false)}
              />
            </div>
            <div className="flex flex-col items-end justify-center w-full h-full col-span-12 row-span-6 row-start-2 gap-10 px-10">
              {navLinks.map((link, index) => (
                <Link
                  key={index}
                  to={link.to}
                  smooth={true}
                  offset={-100}
                  spy={true}
                  activeClass="text-4xl opacity-[1]"
                  className="text-3xl font-bold transition-all opacity-30 text-neutralTextLight dark:text-neutralTextDark"
                  onClick={() => setIsNavOpen(false)}
                >
                  {link.text}
                </Link>
              ))}
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="sticky top-0 z-20 grid items-center grid-cols-12 gap-4 h-14 bg-alternateBackground dark:bg-darkAlternateBackground"
      >
        <Link
          to="home"
          className="col-start-2 cursor-pointer"
          smooth={true}
          offset={-100}
        >
          <PrajSign className="w-8 h-8 dark:stroke-white stroke-[4px] stroke-neutralTextLight" />
        </Link>

        <div className="flex items-center justify-end col-span-4 col-start-8 gap-10">
          {navLinks.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              smooth={true}
              offset={-200}
              spy={true}
              activeClass="opacity-[.9]"
              className="hidden font-bold opacity-50 cursor-pointer text-neutralTextLight dark:text-neutralTextDark md:block"
              onClick={() => setIsNavOpen(false)}
            >
              {link.text}
            </Link>
          ))}
          {theme === "light" ? (
            <PiSun className="w-5 h-5" onClick={() => toggleTheme()} />
          ) : (
            <PiMoon className="w-5 h-5" onClick={() => toggleTheme()} />
          )}
          <PiHamburger
            className="w-5 h-5 md:hidden"
            onClick={() => setIsNavOpen(true)}
          />
        </div>
      </motion.div>
    </>
  );
}
