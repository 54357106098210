import React, { ReactElement, useState } from "react";

import { PiDotBold } from "react-icons/pi";
import CalcLogo from "../../assets/icons/10calc.svg";
import ReactIcon from "../../assets/icons/react.svg";
import JSIcon from "../../assets/icons/js.svg";
import TSIcon from "../../assets/icons/typescript.svg";
import NuxtIcon from "../../assets/icons/nuxt.svg";
import VueIcon from "../../assets/icons/vue.svg";
import CSharpIcon from "../../assets/icons/csharp.svg";
import GraphQLIcon from "../../assets/icons/graphql.svg";
import MongoIcon from "../../assets/icons/mongo.svg";
import NodeIcon from "../../assets/icons/node.svg";
import UnityIcon from "../../assets/icons/unity.svg";
import WindowsIcon from "../../assets/icons/windows.svg";

import { CarouselProvider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { HobbyCarousel } from "./HobbyCarousel";
import { Element } from "react-scroll";
import clsx from "clsx";
import { Reveal } from "../../components/Reveal";
import { AnimatePresence, motion } from "framer-motion";
import { Career } from "./Career";

const CalcImage = new URL("../../assets/images/10Calc.webp", import.meta.url);
const BeFriendImage = new URL(
  "../../assets/images/befriend.webp",
  import.meta.url
);
const PotentialDoddleVideo = new URL(
  "../../assets/videos/Camera-Potential-Doodle.mp4",
  import.meta.url
);
const CampzyImage = new URL(
  "../../assets/images/campzy-frame-chrome-mac.webp",
  import.meta.url
);
const IsGDImage = new URL("../../assets/images/isgdlogo.jpg", import.meta.url);
const GICDungriImage = new URL(
  "../../assets/images/gicdungri.webp",
  import.meta.url
);
const MachaImage = new URL("../../assets/images/macha.webp", import.meta.url);
const RajbhashaImage = new URL(
  "../../assets/images/rajbhasha.webp",
  import.meta.url
);

export type Project = {
  logo?: any;
  logoTitle?: string;
  logoHref: string;
  techStackLogos: any[];
  image?: any;
  imageAlt?: string;
  video?: any;
  videoAlt?: string;
  description: ReactElement;
};

type LifePath = "career" | "hobby";

export function Work() {
  const [selectedLifePath, setSelectedLifePath] = useState<LifePath>("career");

  const hobbyProjects: Project[] = [
    {
      logoTitle: "augicurgam.in",
      logoHref: "https://augicurgam.in/",
      techStackLogos: [TSIcon, NuxtIcon],
      image: GICDungriImage,
      imageAlt: "AuGIC Urgam Image",
      description: (
        <p>Website built for A.U. Govt. Intermediate College Urgam.</p>
      ),
    },
    {
      logoTitle: "Rajbhasha Portal",
      logoHref: "https://github.com/prajjwaldimri/rajbhasha-website",
      techStackLogos: [JSIcon, VueIcon],
      image: RajbhashaImage,
      imageAlt: "Rajbhasha Image",
      description: (
        <p>
          Portal built for Rajbhasha Department of National Institute of
          Technology Goa.
        </p>
      ),
    },
    {
      logo: CalcLogo,
      logoHref: "https://github.com/prajjwaldimri/10Calc",
      techStackLogos: [CSharpIcon, WindowsIcon],
      image: CalcImage,
      imageAlt: "10Calc Image",
      description: (
        <p>
          A super versatile calculator rated 4 stars on Microsoft Store. <br />
          <br />
          "The more I used 10Calc, the more the Windows Phone app grew on me" -{" "}
          <a
            href="https://www.windowscentral.com/10calc-windows-phone-app-review"
            target="_blank"
            className="dark:text-primaryLight text-primary"
          >
            George Ponder, Windows Central
          </a>
        </p>
      ),
    },
    {
      logoTitle: "is.gd.NET",
      logoHref: "https://github.com/prajjwaldimri/is.gd.NET",
      techStackLogos: [CSharpIcon],
      image: IsGDImage,
      imageAlt: "IsGD Logo",
      description: <p>API Client Library for is.gd and v.gd</p>,
    },

    {
      logoTitle: "BeFriend",
      logoHref: "https://github.com/prajjwaldimri/Friend-App",
      techStackLogos: [CSharpIcon, WindowsIcon],
      image: BeFriendImage,
      imageAlt: "Befriend Image",
      description: (
        <p>
          A rape prevention app for Windows Phones. <br />
          <br />
          "The concept here is pretty compelling" -{" "}
          <a
            href="http://allaboutwindowsphone.com/reviews/item/21547_BeFriend.php"
            target="_blank"
            className="dark:text-primaryLight text-primary"
          >
            Steve LitchField, AllAboutWindowsPhone.com
          </a>
        </p>
      ),
    },
    {
      logoTitle: "macha.in",
      logoHref: "https://github.com/prajjwaldimri/macha",
      techStackLogos: [NodeIcon, NuxtIcon, GraphQLIcon, MongoIcon],
      image: MachaImage,
      imageAlt: "Macha Image",
      description: (
        <p>
          An experimental privacy focused social network. <br />
        </p>
      ),
    },
    {
      logoTitle: "Campzy",
      logoHref: "https://github.com/prajjwaldimri/Campzy",
      techStackLogos: [NodeIcon, VueIcon, GraphQLIcon, MongoIcon],
      image: CampzyImage,
      imageAlt: "Campzy Image",
      description: (
        <p>
          Online camp aggregator with payment gateway integration and complete
          inventory management system for camp owners. <br />
        </p>
      ),
    },

    {
      logoTitle: "potential-doodle",
      logoHref: "https://gitlab.com/PrajjwalDimri/potential-doodle",
      techStackLogos: [CSharpIcon, UnityIcon],
      video: PotentialDoddleVideo,
      videoAlt: "Potential Doodle Video",
      description: (
        <p>
          A game which tried to merge Procedural Generation and Car Racing into
          one. <br />
        </p>
      ),
    },
  ];

  return (
    <Element
      name="work"
      className="grid grid-cols-12 gap-8 mt-20 md:px-24 2xl:px-56 md:mt-56 snap-center"
    >
      <div className="flex flex-col items-center justify-center col-span-12 row-start-2 row-end-4 text-center md:items-start md:text-start">
        <Reveal>
          <h1 className="pt-2 font-serif text-4xl md:text-5xl">My Work</h1>
        </Reveal>

        <Reveal delay={0.1}>
          <div className="flex items-center toggle text-primary md:mt-2">
            <button onClick={() => setSelectedLifePath("career")}>
              <h3
                className={clsx("text-md md:text-lg cursor-pointer uppercase", {
                  "opacity-50": selectedLifePath !== "career",
                })}
              >
                Career
              </h3>
            </button>
            <PiDotBold className="w-8 h-8 cursor-pointer" />
            <button onClick={() => setSelectedLifePath("hobby")}>
              <h3
                className={clsx("text-md md:text-lg cursor-pointer uppercase", {
                  "opacity-50": selectedLifePath !== "hobby",
                })}
              >
                Projects
              </h3>
            </button>
          </div>
        </Reveal>
        <Reveal delay={0.2}>
          <AnimatePresence mode="popLayout">
            {selectedLifePath === "career" && (
              <motion.div
                initial={{ opacity: 0, x: -1000 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -1000 }}
                transition={{ duration: 0.5 }}
                key="career"
              >
                <Career />
              </motion.div>
            )}
            {selectedLifePath === "hobby" && (
              <motion.div
                initial={{ opacity: 0, x: 1000 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 1000 }}
                transition={{ duration: 0.5 }}
                key="hobby"
              >
                <CarouselProvider
                  totalSlides={hobbyProjects.length}
                  naturalSlideHeight={100}
                  naturalSlideWidth={100}
                  className="md:w-[100%] w-[100vw] md:mt-2"
                  touchEnabled={true}
                  dragEnabled={true}
                  infinite={true}
                  isIntrinsicHeight={true}
                >
                  <HobbyCarousel hobbyProjects={hobbyProjects} />
                </CarouselProvider>
              </motion.div>
            )}
          </AnimatePresence>
        </Reveal>
      </div>
    </Element>
  );
}
