import { Navbar } from "./components/Navbar";
import { About } from "./ui/About";
import { Footer } from "./ui/Footer";
import { Home } from "./ui/Home";
import { Work } from "./ui/Work";
import { Writing } from "./ui/Writing";

import { motion } from "framer-motion";

export function App() {
  return (
    <motion.div
      layout
      className="min-h-screen dark:bg-neutral-900 dark:text-neutralTextDark text-neutralTextLight min-w-screen snap-y snap-proximity"
    >
      <Navbar />
      <Home />
      <About />
      <Work />
      <Writing />
      <Footer />
    </motion.div>
  );
}
