import { PiGitlabLogo } from "react-icons/pi";
import { Reveal } from "../components/Reveal";
import { motion } from "framer-motion";

export function Footer() {
  return (
    <div className="grid grid-cols-12 gap-8 pt-4 mt-8 md:px-24 2xl:px-56 md:py-10 bg-alternateBackground dark:bg-darkAlternateBackground">
      <div className="flex flex-col items-center col-span-12 gap-1 md:col-span-6">
        <Reveal>
          <p className="text-xs opacity-70 md:text-sm">Links</p>
        </Reveal>
        <Reveal>
          <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              className="text-sm opacity-90 md:text-base"
              download="Prajjwal-Resume.pdf"
              href="/Prajjwal-Resume.pdf"
            >
              Resume
            </a>
          </motion.div>
        </Reveal>
        <Reveal>
          <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              className="text-sm opacity-90 md:text-base"
              href="https://github.com/prajjwaldimri"
              target="_blank"
            >
              GitHub
            </a>
          </motion.div>
        </Reveal>
        <Reveal>
          <motion.div
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <a
              className="text-sm opacity-90 md:text-base"
              href="https://linkedin.com/in/prajjwaldimri"
              target="_blank"
            >
              LinkedIn
            </a>
          </motion.div>
        </Reveal>
      </div>
      <div className="flex flex-col items-center justify-center col-span-12 text-center md:col-span-6">
        <Reveal>
          <p className="flex items-center text-sm opacity-90 md:text-base gap-1">
            Made with{" "}
            <motion.span
              style={{ animation: "heartbeat 1.4s linear infinite" }}
              className="cursor-default"
            >
              ❤️
            </motion.span>{" "}
            on 🌏
          </p>
        </Reveal>
        <Reveal>
          <p className="mt-1 text-xs opacity-80 md:text-sm">
            © {new Date().getFullYear()}. All rights reserved.
          </p>
        </Reveal>
      </div>
    </div>
  );
}
