import {
  PiArrowSquareLeft,
  PiArrowSquareOut,
  PiArrowSquareRight,
} from "react-icons/pi";

import {
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
} from "pure-react-carousel";
import { Project } from ".";
import { useContext, useState, useEffect } from "react";
import { motion } from "framer-motion";

type HobbyCarouselProps = {
  hobbyProjects: Project[];
};

export function HobbyCarousel({ hobbyProjects }: Readonly<HobbyCarouselProps>) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  );
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <>
      <Slider>
        {hobbyProjects?.map((project, index) => (
          <Slide key={index} index={index} innerClassName="flex">
            <div className="grid grid-cols-12 px-8 md:px-0 md:w-full">
              <div className="flex flex-col items-center justify-center col-span-12 mt-4 md:items-start md:col-span-7">
                <div className="flex flex-col items-start gap-2 justify-center">
                  <a href={project.logoHref} target="_blank">
                    {project.logo && (
                      <div className="flex items-end">
                        <project.logo className="h-8 md:h-10 fill-black dark:fill-white" />
                        <PiArrowSquareOut className="inline-block w-6 h-6 ml-2 mb-1 opacity-60" />
                      </div>
                    )}
                    {!project.logo && project.logoTitle && (
                      <motion.div
                        initial={{ scale: 1 }}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className="flex items-center"
                      >
                        <h2 className="font-serif text-4xl">
                          {project.logoTitle}
                          <PiArrowSquareOut className="inline-block w-6 h-6 ml-2 mb-1 opacity-60" />
                        </h2>
                      </motion.div>
                    )}
                  </a>
                  <div className="flex items-center gap-2 w-full justify-center md:justify-start">
                    {project.techStackLogos.map((TechStackLogo, index) => (
                      <TechStackLogo key={index} className="h-6 fill-current" />
                    ))}
                  </div>
                </div>
                {project.image && (
                  <img
                    src={project.image.toString()}
                    alt={project.imageAlt}
                    className="mt-4 h-60 md:hidden"
                    style={{ width: "auto" }}
                  />
                )}
                {project.video && (
                  <video
                    src={project.video.toString()}
                    alt={project.videoAlt}
                    className="mt-4 h-60 md:hidden"
                    style={{ width: "auto" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                )}
                <div className="mt-8 md:text-lg">{project.description}</div>
              </div>

              <div className="items-center justify-end lg:justify-start hidden col-span-4 col-start-9 md:flex">
                {project.image && (
                  <img
                    src={project.image.toString()}
                    alt={project.imageAlt}
                    className="h-64 mt-4"
                    style={{ width: "auto" }}
                  />
                )}
                {project.video && (
                  <video
                    src={project.video.toString()}
                    alt={project.videoAlt}
                    className="mt-4 h-64"
                    style={{ width: "auto" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                )}
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
      <div className="flex items-center ml-auto mr-auto w-fit md:ml-0 md:mt-0">
        <ButtonBack>
          <PiArrowSquareLeft className="w-8 h-8" />
        </ButtonBack>
        <p className="px-4 text-sm">
          {currentSlide + 1} / {hobbyProjects.length}
        </p>
        <ButtonNext>
          <PiArrowSquareRight className="w-8 h-8 cursor-pointer" />
        </ButtonNext>
      </div>
    </>
  );
}
